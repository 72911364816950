body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-wrap {
  white-space: normal !important; /* Allow text to wrap */
  text-align: center; /* Optional: Center align the text */
}

.header-center {
  text-align: center; /* Center align the header text */
}
.stretched-text {
  letter-spacing: 2px;
  display: inline-block;
  font-size: 32px;
  transform: scaleX(1.5);
  transform-origin: 0 0;
  margin-bottom: -50%;
}
/* table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

thead {
  background: lightgray;
} */
